
























import { Button, CellGroup, DatetimePicker, Dialog, Field, Popup, Toast } from "vant";
import Vue from "vue";
import Component from "vue-class-component";
import Moment from "moment";
Moment.locale("zh-cn");

@Component({
  components: {
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker
  }
})
export default class BatteryPlanOrderExtraPaySave extends Vue {
  extraPayId = 0;
  extraPay = {
    id: 0,
    planOrderId: 0,
    name: "",
    amount: 0,
    deposit: 0,
    count: 0,
    expireTime:
      Moment()
        .hour(23)
        .minute(59)
        .seconds(59)
        .unix() * 1000,
    state: 0
  };

  datePicker = {
    show: false,
    date: null as null | Date
  };
  showDatePicker() {
    this.datePicker.date = new Date(this.extraPay.expireTime);
    this.datePicker.show = true;
  }
  onConfirmDatePicker(v: Date) {
    this.extraPay.expireTime = v.getTime() + (86400 - 1) * 1000;
    this.datePicker.show = false;
  }

  get amount() {
    return this.extraPay.amount / 100;
  }
  set amount(v: number) {
    this.extraPay.amount = v * 100;
  }

  get deposit() {
    return this.extraPay.deposit / 100;
  }
  set deposit(v: number) {
    this.extraPay.deposit = v * 100;
  }

  get expireTimeMsg() {
    return Moment(this.extraPay.expireTime).format("YYYY年MM月DD日");
  }

  created() {
    this.extraPayId = Number(this.$route.params.extraPayId);
    if (this.extraPayId != 0) {
      this.getData();
    } else {
      this.extraPay.planOrderId = Number(this.$route.query.planOrderId || 0);
    }
  }

  getData() {
    this.$axios.post("/api/manage/getBatteryPlanOrderExtraPay", { extraPayId: this.extraPayId }).then(res => {
      const data = res.data.data;
      this.extraPay = data;
    });
  }
  save() {
    this.$axios.post("/api/manage/saveBatteryPlanOrderExtraPay", this.extraPay).then(() => {
      Toast.success({
        message: "保存成功",
        duration: 500,
        onClose: () => {
          this.$router.go(-1);
        }
      });
    });
  }
  close() {
    Dialog.confirm({
      message: "您确定要关闭这笔支付吗?"
    }).then(() => {
      this.$axios.post("/api/manage/closeBatteryPlanOrderExtraPay", { extraPayId: this.extraPayId }).then(() => {
        this.getData();
        Toast.success("关闭成功");
      });
    });
  }
}
